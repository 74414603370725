var APPLY_FORM_URL = 'https://ats.rce.eu/hr/job/import/jbjz_json';

var calculatorData = [
  {
    "age": 16,
    "wage_per_hour": 7.02,
    "hours_per_week": 5,
    "weekly_wage": 35.1,
    "salary_per_month": 152.1
  },
  {
    "age": 17,
    "wage_per_hour": 8.43,
    "hours_per_week": 5,
    "weekly_wage": 42.15,
    "salary_per_month": 182.65
  },
  {
    "age": 18,
    "wage_per_hour": 9.82,
    "hours_per_week": 5,
    "weekly_wage": 49.1,
    "salary_per_month": 212.77
  },
  {
    "age": 19,
    "wage_per_hour": 11.23,
    "hours_per_week": 5,
    "weekly_wage": 56.15,
    "salary_per_month": 243.32
  },
  {
    "age": 20,
    "wage_per_hour": 12.63,
    "hours_per_week": 5,
    "weekly_wage": 63.15,
    "salary_per_month": 273.65
  },
  {
    "age": 21,
    "wage_per_hour": 14.33,
    "hours_per_week": 5,
    "weekly_wage": 71.65,
    "salary_per_month": 310.48
  },
  {
    "age": 16,
    "wage_per_hour": 7.02,
    "hours_per_week": 10,
    "weekly_wage": 70.2,
    "salary_per_month": 304.2
  },
  {
    "age": 17,
    "wage_per_hour": 8.43,
    "hours_per_week": 10,
    "weekly_wage": 84.3,
    "salary_per_month": 365.3
  },
  {
    "age": 18,
    "wage_per_hour": 9.82,
    "hours_per_week": 10,
    "weekly_wage": 98.2,
    "salary_per_month": 425.53
  },
  {
    "age": 19,
    "wage_per_hour": 11.23,
    "hours_per_week": 10,
    "weekly_wage": 112.3,
    "salary_per_month": 486.63
  },
  {
    "age": 20,
    "wage_per_hour": 12.63,
    "hours_per_week": 10,
    "weekly_wage": 126.3,
    "salary_per_month": 547.3
  },
  {
    "age": 21,
    "wage_per_hour": 14.33,
    "hours_per_week": 10,
    "weekly_wage": 143.3,
    "salary_per_month": 620.97
  },
  {
    "age": 16,
    "wage_per_hour": 7.02,
    "hours_per_week": 15,
    "weekly_wage": 105.3,
    "salary_per_month": 456.3
  },
  {
    "age": 17,
    "wage_per_hour": 8.43,
    "hours_per_week": 15,
    "weekly_wage": 126.45,
    "salary_per_month": 547.95
  },
  {
    "age": 18,
    "wage_per_hour": 9.82,
    "hours_per_week": 15,
    "weekly_wage": 147.3,
    "salary_per_month": 638.3
  },
  {
    "age": 19,
    "wage_per_hour": 11.23,
    "hours_per_week": 15,
    "weekly_wage": 168.45,
    "salary_per_month": 729.95
  },
  {
    "age": 20,
    "wage_per_hour": 12.63,
    "hours_per_week": 15,
    "weekly_wage": 189.45,
    "salary_per_month": 820.95
  },
  {
    "age": 21,
    "wage_per_hour": 14.33,
    "hours_per_week": 15,
    "weekly_wage": 214.95,
    "salary_per_month": 931.45
  },
  {
    "age": 16,
    "wage_per_hour": 7.02,
    "hours_per_week": 20,
    "weekly_wage": 140.4,
    "salary_per_month": 608.4
  },
  {
    "age": 17,
    "wage_per_hour": 8.43,
    "hours_per_week": 20,
    "weekly_wage": 168.6,
    "salary_per_month": 730.6
  },
  {
    "age": 18,
    "wage_per_hour": 9.82,
    "hours_per_week": 20,
    "weekly_wage": 196.4,
    "salary_per_month": 851.07
  },
  {
    "age": 19,
    "wage_per_hour": 11.23,
    "hours_per_week": 20,
    "weekly_wage": 224.6,
    "salary_per_month": 973.27
  },
  {
    "age": 20,
    "wage_per_hour": 12.63,
    "hours_per_week": 20,
    "weekly_wage": 252.6,
    "salary_per_month": 1094.6
  },
  {
    "age": 21,
    "wage_per_hour": 14.33,
    "hours_per_week": 20,
    "weekly_wage": 286.6,
    "salary_per_month": 1241.93
  },
  {
    "age": 16,
    "wage_per_hour": 7.02,
    "hours_per_week": 25,
    "weekly_wage": 175.5,
    "salary_per_month": 760.5
  },
  {
    "age": 17,
    "wage_per_hour": 8.43,
    "hours_per_week": 25,
    "weekly_wage": 210.75,
    "salary_per_month": 913.25
  },
  {
    "age": 18,
    "wage_per_hour": 9.82,
    "hours_per_week": 25,
    "weekly_wage": 245.5,
    "salary_per_month": 1063.83
  },
  {
    "age": 19,
    "wage_per_hour": 11.23,
    "hours_per_week": 25,
    "weekly_wage": 280.75,
    "salary_per_month": 1216.58
  },
  {
    "age": 20,
    "wage_per_hour": 12.63,
    "hours_per_week": 25,
    "weekly_wage": 315.75,
    "salary_per_month": 1368.25
  },
  {
    "age": 21,
    "wage_per_hour": 14.33,
    "hours_per_week": 25,
    "weekly_wage": 358.25,
    "salary_per_month": 1552.42
  },
  {
    "age": 16,
    "wage_per_hour": 7.02,
    "hours_per_week": 30,
    "weekly_wage": 210.6,
    "salary_per_month": 912.6
  },
  {
    "age": 17,
    "wage_per_hour": 8.43,
    "hours_per_week": 30,
    "weekly_wage": 252.9,
    "salary_per_month": 1095.9
  },
  {
    "age": 18,
    "wage_per_hour": 9.82,
    "hours_per_week": 30,
    "weekly_wage": 294.6,
    "salary_per_month": 1276.6
  },
  {
    "age": 19,
    "wage_per_hour": 11.23,
    "hours_per_week": 30,
    "weekly_wage": 336.9,
    "salary_per_month": 1459.9
  },
  {
    "age": 20,
    "wage_per_hour": 12.63,
    "hours_per_week": 30,
    "weekly_wage": 378.9,
    "salary_per_month": 1641.9
  },
  {
    "age": 21,
    "wage_per_hour": 14.33,
    "hours_per_week": 30,
    "weekly_wage": 429.9,
    "salary_per_month": 1862.9
  },
  {
    "age": 16,
    "wage_per_hour": 7.02,
    "hours_per_week": 32,
    "weekly_wage": 224.64,
    "salary_per_month": 973.44
  },
  {
    "age": 17,
    "wage_per_hour": 8.43,
    "hours_per_week": 32,
    "weekly_wage": 269.76,
    "salary_per_month": 1168.96
  },
  {
    "age": 18,
    "wage_per_hour": 9.82,
    "hours_per_week": 32,
    "weekly_wage": 314.24,
    "salary_per_month": 1361.71
  },
  {
    "age": 19,
    "wage_per_hour": 11.23,
    "hours_per_week": 32,
    "weekly_wage": 359.36,
    "salary_per_month": 1557.23
  },
  {
    "age": 20,
    "wage_per_hour": 12.63,
    "hours_per_week": 32,
    "weekly_wage": 404.16,
    "salary_per_month": 1751.36
  },
  {
    "age": 21,
    "wage_per_hour": 14.33,
    "hours_per_week": 32,
    "weekly_wage": 458.56,
    "salary_per_month": 1987.09
  },
  {
    "age": 16,
    "wage_per_hour": 7.02,
    "hours_per_week": 38,
    "weekly_wage": 266.76,
    "salary_per_month": 1155.96
  },
  {
    "age": 17,
    "wage_per_hour": 8.43,
    "hours_per_week": 38,
    "weekly_wage": 320.34,
    "salary_per_month": 1388.14
  },
  {
    "age": 18,
    "wage_per_hour": 9.82,
    "hours_per_week": 38,
    "weekly_wage": 373.16,
    "salary_per_month": 1617.03
  },
  {
    "age": 19,
    "wage_per_hour": 11.23,
    "hours_per_week": 38,
    "weekly_wage": 426.74,
    "salary_per_month": 1849.21
  },
  {
    "age": 20,
    "wage_per_hour": 12.63,
    "hours_per_week": 38,
    "weekly_wage": 479.94,
    "salary_per_month": 2079.74
  },
  {
    "age": 21,
    "wage_per_hour": 14.33,
    "hours_per_week": 38,
    "weekly_wage": 544.54,
    "salary_per_month": 2359.67
  }
]



const calculatorForm = () => {
  return {
    calculatorData: calculatorData,
    age: 16,
    hoursPerWeek: 20,
    moneyFilter(val) {
      var euroFormat = Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return euroFormat.format(val);
    },
    outputSalary() {
      var result = calculatorData.find((o) => {
        return (o.age === this.age) && (o.hours_per_week === this.hoursPerWeek);
      });
      return (result && result.salary_per_month.toFixed(2).replace('.', ',')) || 'NaN';
    }
  }
}

const rootApp = () => {
  return {
    showModal: false,
    formData: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      place: '',
      dob: '',
      vacancy_id: '',
      // Extra fields
      company: '',

      utm_source: docCookies.getItem('utm_source'),
      utm_medium: docCookies.getItem('utm_medium'),
      utm_campaign: docCookies.getItem('utm_campaign'),
      utm_term: docCookies.getItem('utm_term'),
      utm_content: docCookies.getItem('utm_content')
    },
    vacancies: [
      {name: 'Bediening', value: '6920'},
      {name: 'Keuken', value: '6921'},
      {name: 'Spoelkeuken', value: '6922'},
      {name: 'Geen voorkeur', value: '6923'},
    ],
    errorMessage: '',
    formLoading: false,
    successNotification: false,
    buttonText: 'Verstuur',
    submitForm() {
      console.log('Submit', this.formData);
      //
      this.errorMessage = ''
      this.formLoading = true;
      this.buttonText = 'Verzenden...';

      axios.post(APPLY_FORM_URL, this.formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log('THEN', response)
        if (response.data && response.data.hasOwnProperty('error')) {
          var e = response.data.error
          throw new Error(e.message);
        } else {
          this.openToast();
          this.first_name = ''
          this.last_name = ''
          this.email = ''
          this.phone = ''
          this.place = ''
          this.dob = ''
          this.vacancy_id = ''
          this.company = ''
        }
      })
      .catch((e) => {
        let message = e.message
        message = `
          ${e.code ? e.code+': ' : ''}
          ${e.name ? e.name+': ' : ''}
          ${message || 'Server Error: Something went wrong.'}
          `
        this.errorMessage = message;
      })
      .finally(() => {
        this.formLoading = false;
        this.buttonText = 'Verstuur';
      });
    },
    openToast() {
      this.successNotification = true;
      setTimeout(() => {
        this.successNotification = false;
      }, 5000);
    },
    queryParamsProcessor() {
      var eligibleParams = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content'
      ];
      var params = new URLSearchParams(window.location.search);

      for (const param of params) {
        console.log(param)
        var paramKey = param[0]
        var paramValue = param[1]
        if (eligibleParams.includes(paramKey)) {
          this.formData[paramKey] = paramValue
          docCookies.setItem(paramKey, paramValue);
        }
      }
    },
    init() {
      this.queryParamsProcessor();
    }
  }
}
